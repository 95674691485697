<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="isLoading">
            <b-alert v-if="!booking" variant="danger" show dismissible fade><b>{{ $t('external_research.you_have_already_subscribe_in_this_time_period') }}</b> </b-alert>
            <b-row class="p-3">
                <b-col lg="12" sm="12">
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form id="form"  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                            <fieldset class="p-2 w-100">
                                <legend class="px-2 w-50 shadow-sm">{{ $t('external_research.subscription_details') }}</legend>
                                <b-row class="p-3">
                                    <b-col lg="4" sm="12">
                                        <ValidationProvider name="Subscription Type" vid="fee_type" rules="required|min_value:1">
                                            <b-form-group
                                                label-for="fee_type"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('external_research.subscription_type')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="publication.fee_type"
                                                :options="publicationFeeTypeList"
                                                id="fee_type"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" sm="12">
                                        <ValidationProvider name="Number of Subscription" vid="number_of_subscription" :rules="`required|min_value:${min_quantity}|max_value:${max_quantity}`">
                                            <b-form-group
                                                label-for="number_of_subscription"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('external_research.number_of_subscription')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                    id="number_of_subscription"
                                                    type="number"
                                                    :placeholder="$t('external_research.number_of_subscription')"
                                                    v-model="publication.number_of_subscription"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" sm="12">
                                        <ValidationProvider name="For Years" vid="year_no" rules="required|min_value:1">
                                            <b-form-group
                                                label-for="year_no"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('external_research.for_year')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="publication.year_no"
                                                :options="getForYearsList"
                                                id="year_no"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" sm="12">
                                        <div class="form-row">
                                            <div class="col-12">
                                            <label for="start_date" class="d-block"> {{$t('globalTrans.subscription_start_date')}}<span class="text-danger">*</span></label>
                                            </div>
                                            <div class="col">
                                                <ValidationProvider name="Year" vid="year" rules="required|min_value:1">
                                                    <div slot-scope="{ valid, errors }">
                                                        <b-form-select
                                                            plain
                                                            v-model="publication.year"
                                                            :options="yearList"
                                                            id="year"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col">
                                                <ValidationProvider name="Month" vid="month" rules="required|min_value:1">
                                                    <div slot-scope="{ valid, errors }">
                                                        <b-form-select
                                                            plain
                                                            v-model="publication.month"
                                                            :options="month"
                                                            id="month_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col lg="4" sm="12">
                                        <div class="form-row">
                                            <div class="col-12">
                                                <label for="end_date" class="d-block"> {{$t('globalTrans.subscription_end_date')}}<span class="text-danger">*</span></label>
                                            </div>
                                            <div class="col">
                                                <ValidationProvider name="Year" vid="year" rules="required|min_value:1">
                                                    <div slot-scope="{ valid, errors }">
                                                        <b-form-select
                                                            plain
                                                            v-model="publication.end_year"
                                                            :options="yearListEnd"
                                                            id="year"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            disabled
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col">
                                                <ValidationProvider name="Month" vid="month" rules="required|min_value:1">
                                                    <div slot-scope="{ valid, errors }">
                                                        <b-form-select
                                                            plain
                                                            v-model="publication.end_month"
                                                            :options="monthList"
                                                            id="month_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            disabled
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col xl="4" lg="4" md="6" sm="12">
                                        <ValidationProvider name="Publication Number" vid="pub_number" :rules="{required: true, regex: /^\d*\.?\d*$/}">
                                            <b-form-group
                                                label-for="pub_number"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('external_research.pub_number')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                id="pub_number"
                                                disabled
                                                v-model="publication.pub_number"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                                <div class="invalid-feedback text-dark bg-warning px-2 d-block">
                                                    {{
                                                        '[' + $t('globalTrans.note') + ': ' + $n(publication.year_no) + ' ' + $t('external_research.year')
                                                        + ' X ' + $n(12) + ' ' + $t('external_research.month') + ' (' + $t('external_research.per_month_one')
                                                        + ')' + ' X ' + $n(publication.number_of_subscription) + ' ' + $t('external_research.number_of_subscription') + '= ' + $n(publication.pub_number) + ' ' + $t('globalTrans.copies') + ']'
                                                    }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xl="4" lg="4" md="6" sm="12">
                                        <ValidationProvider name="Total Publication Fee" vid="total_pub_fee" :rules="{required: true, regex: /^\d*\.?\d*$/}">
                                            <b-form-group
                                                label-for="total_pub_fee"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('globalTrans.total_amount') + ' ' + $t('globalTrans.taka') }} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                id="total_pub_fee"
                                                disabled
                                                v-model="publication.pub_total_fee"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                                <div class="invalid-feedback text-dark bg-warning px-2 d-block">
                                                    {{
                                                        '[' + $t('globalTrans.note') + ': ' + $n(publication.year_no) + ' ' + $t('external_research.year') + ' X '
                                                        + $n(publication.fee_amount) + ' ' + $t('external_research.taka') + ' (' + $t('external_research.per_twelve_copy') + ')' + ' X '
                                                        + $n(publication.number_of_subscription) + ' ' + $t('external_research.number_of_subscription')
                                                        + ' = ' + $n(publication.pub_total_fee) + ' ' + $t('external_research.taka') + ']'
                                                    }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xl="4" lg="6" md="4" sm="12">
                                        <ValidationProvider name="Mailing Address" vid="mailing_address" rules="required">
                                            <b-form-group
                                                label-for="mailing_address"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('external_research.mailing_address')}} ({{$t('globalTrans.en')}})<span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                id="mailing_address"
                                                v-model="publication.mailing_address"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-col xl="12" lg="12" md="12" sm="12">
                                    <b-button  @click="subsAdd" class="btn btn-success float-right" v-b-toggle.my-collapse>{{$t('external_research.subs_address_info_add')}}</b-button>
                                </b-col>
                            </fieldset>
                            <b-collapse id="my-collapse">
                                <b-row>
                                    <div class="col-md-12 dam-form">
                                        <fieldset class="p-2 w-100">
                                            <legend class="px-2 w-50 shadow-sm">{{$t('external_research.subs_address_info')}}</legend>
                                            <div class="col-12">
                                                <table class="table" style="width:100%" border="1">
                                                    <thead class="thead">
                                                        <tr>
                                                            <th style="width:18%">{{$t('globalTrans.name')}} <span class="text-danger">*</span></th>
                                                            <th style="width:18%">{{$t('globalTrans.mobile')}} <span class="text-danger">*</span></th>
                                                            <th style="width:20%">{{$t('globalTrans.address')}} <span class="text-danger">*</span></th>
                                                            <th style="width:20%">{{$t('globalTrans.quantity')}} <span class="text-danger">*</span></th>
                                                            <th style="width:5%"></th>
                                                        </tr>
                                                    </thead>
                                                    <tr v-for="(address, index) in publication.address_info" :key="index">
                                                        <td style="width:18%">
                                                            <ValidationProvider :vid="'name' + index"  name="Name" :rules="`${publication.isaddress_add ? 'required' : ''}`">
                                                                <div slot-scope="{ valid, errors }">
                                                                    <b-form-input
                                                                        rows="2"
                                                                        v-model="address.name"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="error invalid-feedback" role="alert">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </div>
                                                            </ValidationProvider>
                                                        </td>
                                                        <td style="width:18%">
                                                            <ValidationProvider :vid="'mobile' + index"  name="Mobile" :rules="`${publication.isaddress_add ? 'required|numeric|min:11|max:11' : ''}`">
                                                                <div slot-scope="{ valid, errors }">
                                                                    <b-form-input
                                                                        rows="2"
                                                                        v-model="address.mobile"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="error invalid-feedback" role="alert">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </div>
                                                            </ValidationProvider>
                                                        </td>
                                                        <td style="width:20%">
                                                            <ValidationProvider :vid="'address' + index"  name="Address" :rules="`${publication.isaddress_add ? 'required' : ''}`">
                                                                <div slot-scope="{ valid, errors }">
                                                                    <b-form-input
                                                                        rows="2"
                                                                        v-model="address.address"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="error invalid-feedback" role="alert">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </div>
                                                            </ValidationProvider>
                                                        </td>
                                                        <td style="width:20%">
                                                            <ValidationProvider :vid="'quantity' + index"  name="Quantity" :rules="`${publication.isaddress_add ? 'required' : ''}`">
                                                                <div slot-scope="{ valid, errors }">
                                                                    <!-- <input :state="errors[0] ? false : (valid ? true : null)" @change="checkSortOrder(address,$event)"  type="number" :value="address.quantity"  class="form-control"/> -->
                                                                    <b-form-input
                                                                        rows="2"
                                                                        v-model="address.quantity"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                        @input="quantityChecking"
                                                                    ></b-form-input>
                                                                    <div class="error invalid-feedback" role="alert">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </div>
                                                            </ValidationProvider>
                                                        </td>
                                                        <td style="width:5%">
                                                            <button v-if="index !== 0" @click="removeIt(index, lesson)" class="btn btn-sm btn-danger float-right" type="button">
                                                                <i class="fas fa-window-close m-0"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <button
                                                                @click="addMore"
                                                                class="btn btn-sm btn-primary float-right"
                                                                type="button"
                                                            ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </fieldset>
                                    </div>
                                </b-row>
                            </b-collapse>
                            <b-row class="mt-2">
                                <fieldset class="p-2 w-100">
                                    <legend class="px-2 w-50 shadow-sm">{{ $t('external_sidebar.profile') + ' ' + $t('sidebar.information') }}</legend>
                                    <b-row class="p-3">
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Name" vid="name" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="name"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('globalTrans.name')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    disabled
                                                    id="name"
                                                    v-model="profile.name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="Mobile No" vid="mobile_no" rules="required|numeric|min:11|max:11">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="mobile_no"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('globalUserData.mobile_no')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    disabled
                                                    id="mobile_no"
                                                    v-model="profile.mobile_no"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="NID No" vid="nid" rules="required|numeric">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="nid"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('globalTrans.nid')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    disabled
                                                    id="nid"
                                                    v-model="profile.nid"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="division_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="profile.division_id"
                                                    :options="divisionList"
                                                    id="division_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="district_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="profile.district_id"
                                                :options="districtList"
                                                id="district_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="office_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('org_pro.is_regional_office')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="publication.office_id"
                                                        :options="officeList"
                                                        id="office_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        disabled
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </fieldset>
                            </b-row>
                            <hr>
                            <div class="row">
                            <div class="col-sm-3"></div>
                                <div class="col text-right">
                                    <b-button v-if="submitIt" type="submit" :disabled="isLoading" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                    &nbsp;
                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                            </div>
                        </b-form>
                    </ValidationObserver>
                </b-col>
            </b-row>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
.alert .close {
    color: red !important;
}
.flatpickr-wrapper {
    width: 100%;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { subscriptionFormStoreApi, profileListData } from '../../api/routes'
import { mapGetters } from 'vuex'
import moment from 'moment'
// import flatpickr from 'flatpickr'

export default {
  name: 'FormLayout',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
        saveBtnName: this.id ? this.$t('globalTrans.payment') : this.$t('globalTrans.save'),
        isLoading: false,
        isAdd: true,
        booking: true,
        min_quantity: 0,
        max_quantity: 0,
        publication: {
            fee_type: 0,
            year_no: 0,
            mailing_address: '',
            pub_total_fee: '',
            fee_amount: '',
            number_of_subscription: 0,
            pub_number: '',
            pub_type_id: 0,
            pub_title_id: 0,
            year: 0,
            month: 0,
            end_year: 0,
            end_month: 0,
            start_date: null,
            end_date: null,
            pub_fee_id: 0,
            office_id: 0,
            isaddress_add: false,
            address_info: [
                {
                    id: '',
                    name: null,
                    mobile: null,
                    address: null,
                    quantity: null
                }
            ],
            subscription_date: new Date().toISOString().substr(0, 10)
        },
        submitIt: true,
        profile: {
            name: '',
            gender: '',
            occupation: '',
            email: '',
            division_id: '',
            district_id: '',
            mobile_no: '',
            nid: '',
            address: ''
        },
        configs: { static: true },
        publicationTitleList: [],
        month: [],
        forYearsList: [
            { value: 1, text_en: '1', text_bn: '১' },
            { value: 2, text_en: '2', text_bn: '২' },
            { value: 3, text_en: '3', text_bn: '৩' }
        ]
    }
  },
  computed: {
    officeList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.officeList
    },
    divisionList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
    },
    districtList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0)
    },
    yearList: function () {
      var max = new Date().getFullYear()
      var min = max + 10
      var years = []
      for (var i = max; i <= min; i++) {
        const yearData = {}
        yearData.value = i
        yearData.text = this.$i18n.locale === 'bn' ? this.$n(i, { useGrouping: false }) : i
        yearData.text_en = i
        yearData.text_bn = i
        years.push(yearData)
      }
      return years
      // return this.$store.state.SeedsFertilizer.commonObj.yearList
    },
    yearListEnd: function () {
      var max = new Date().getFullYear()
      var min = max + 11
      var years = []
      for (var i = max; i <= min; i++) {
        const yearData = {}
        yearData.value = i
        yearData.text = this.$i18n.locale === 'bn' ? this.$n(i, { useGrouping: false }) : i
        yearData.text_en = i
        yearData.text_bn = i
        years.push(yearData)
      }
      return years
      // return this.$store.state.SeedsFertilizer.commonObj.yearList
    },
    monthList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.monthList.map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    getForYearsList: function () {
        return this.forYearsList.map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    publicationTypeList: function () {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTypeList.filter(type => type.status === 1).map(obj => {
          if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    publicationFeeTypeList: function () {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationFeeTypeList.map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    currentLocale () {
        return this.$i18n.locale
    },
    ...mapGetters({
        authUser: 'Auth/authUser'
    })
  },
  created () {
    if (this.id) {
      const tmp = this.getData()
      this.publication.pub_type_id = tmp.pub_type_id
      this.publication.pub_title_id = tmp.id
    }
    if (this.authUser.user_id) {
      this.loadAuthProfileData()
    }
  },
  mounted () {
    core.index()
    // flatpickr('#subscription_date', {})
  },
   watch: {
    'publication.year': function (newVal) {
        const year = new Date().getFullYear()
        if (parseInt(newVal) === parseInt(year)) {
            const monthList = []
            const todayMonth = new Date().getMonth()
            const prevMonth = this.$store.state.ExternalUserIrrigation.commonObj.monthList
            prevMonth.map(item => {
                if (parseInt(todayMonth) <= parseInt(item.value)) {
                    item.text = this.$i18n.locale === 'en' ? item.text_en : item.text_bn
                    monthList.push(item)
                }
            })
            this.month = monthList
        } else {
            this.month = this.$store.state.ExternalUserIrrigation.commonObj.monthList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        }
        this.yearAdd()
    },
    'publication.month': function (newVal) {
        this.yearAdd()
    },
    'publication.pub_type_id': function (newVal) {
      this.publicationTitleList = this.getPublicationTitleList(newVal)
    },
    'publication.fee_type': function (newVal) {
        if (parseInt(newVal) === 1) {
            this.min_quantity = 1
            this.max_quantity = 19
        }
        if (parseInt(newVal) === 2) {
            this.min_quantity = 20
            this.max_quantity = 100
        }
        this.calculateTotalFee(newVal)
    },
    'publication.year_no': function (newVal) {
        this.calculateTotalFee(newVal)
        this.yearAdd()
    },
    'publication.number_of_subscription': function (newVal) {
        this.calculateTotalFee()
    }
  },
  methods: {
    checkSortOrder: function (item, event) {
        item.old_serial = item.sorting_order
        item.quantity = event.target.value
        // var total = 0
        // this.publication.address_info.forEach((element, key) => {
        //     total += parseInt(element.quantity)
        // })
        // if (total > this.publication.number_of_subscription) {
        //     item.quantity = item.old_serial
        //     this.$toast.error({
        //         title: 'Warning',
        //         message: 'Total Subscription is more than Enter',
        //         color: '#D6E09B'
        //     })
        // }
    },
    quantityChecking () {
        if (this.publication.isaddress_add) {
            var total = 0
            this.publication.address_info.forEach((element, key) => {
                total += parseInt(element.quantity)
            })
            this.submitIt = true
            if (total > this.publication.number_of_subscription) {
                this.submitIt = false
                this.$toast.error({
                    title: 'Warning',
                    message: 'Total Subscription is more than Enter',
                    color: '#D6E09B'
                })
            }
        }
    },
    removeIt (index, data) {
      this.publication.address_info.splice(index, 1)
      this.quantityChecking()
    },
    subsAdd () {
        this.publication.isaddress_add = !this.publication.isaddress_add
    },
    addMore () {
      this.publication.address_info.push({
        id: '',
        name: null,
        mobile: null,
        address: null,
        quantity: null
      })
    },
    yearAdd () {
        if (this.publication.year && this.publication.month) {
            moment.locale('en')
            let monthIt = this.publication.month
            if (monthIt < 10) {
                monthIt = '0' + monthIt
            }
            const fromDate = this.publication.year + '-' + monthIt + '-01'
            this.publication.start_date = fromDate
            const endDate = moment(fromDate).subtract(1, 'days').add(parseInt(this.publication.year_no), 'years').format('YYYY-MM-DD')
            this.publication.end_date = endDate
            this.publication.end_year = moment(endDate).format('YYYY')
            this.publication.end_month = parseInt(moment(endDate).format('MM'))
        }
    },
    calculateTotalFee () {
        if (this.publication.year_no !== 0 && this.publication.fee_type !== 0) {
            const Fee = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationFeeList.find(item => item.pub_title_id === parseInt(this.publication.pub_title_id) && item.fee_type === this.publication.fee_type)
            if (Fee !== undefined) {
                this.publication.fee_amount = Fee.fee_amount
                if (this.publication.year_no === 1) {
                    this.publication.pub_total_fee = Fee.fee_amount * parseFloat(this.publication.number_of_subscription)
                    this.publication.pub_number = 12 * parseFloat(this.publication.number_of_subscription)
                }
                if (this.publication.year_no === 2) {
                    this.publication.pub_total_fee = parseFloat(Fee.fee_amount) * 2 * parseFloat(this.publication.number_of_subscription)
                    this.publication.pub_number = 12 * 2 * parseFloat(this.publication.number_of_subscription)
                }
                if (this.publication.year_no === 3) {
                    this.publication.pub_total_fee = parseFloat(Fee.fee_amount) * 3 * parseFloat(this.publication.number_of_subscription)
                    this.publication.pub_number = 12 * 3 * parseFloat(this.publication.number_of_subscription)
                }
            } else {
                this.publication.pub_total_fee = ''
            }
        }
    },
    getPublicationTitleList (Id) {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTitleList.filter(item => item.status === 1 && item.pub_type_id === Id).map(obj => {
          if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    loadAuthProfileData () {
        this.isLoading = true
        RestApi.getData(agriResearchServiceBaseUrl, profileListData, { user_id: this.authUser.user_id }).then(response => {
            if (response.success) {
                const data = response.data
                this.profile.name = this.$i18n.locale === 'bn' ? data.name_bn : data.name
                this.profile.name_en = data.name
                this.profile.gender = this.getGenderName(data.gender)
                this.profile.nid = data.nid
                this.profile.email = data.email
                this.profile.mobile_no = data.mobile_no
                this.profile.division_id = data.division_id
                this.profile.district_id = data.district_id
                this.publication.office_id = response.officeId
                this.profile.occupation = this.$i18n.locale === 'bn' ? data.occupation_bn : data.occupation
                this.profile.address = this.$i18n.locale === 'bn' ? data.address_bn : data.address_en
                this.isLoading = false
            } else {
                this.profile = {}
                this.isLoading = false
            }
        })
    },
    getGenderName (Id) {
        const Obj = this.$store.state.ExternalUserIrrigation.genderList.find(newItem => newItem.value === Id)
        if (this.$i18n.locale === 'bn') {
            return Obj.text_bn
        } else {
            return Obj.text_en
        }
    },
    async saveData () {
        this.isLoading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        const data = {
            publication: this.publication,
            profile: this.profile
        }
        result = await RestApi.postData(agriResearchServiceBaseUrl, subscriptionFormStoreApi, data)
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
                this.$store.dispatch('ExternalAgriResearch/mutateAgriResearchExternalDropdown', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: 'Success',
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.isLoading = false
                this.$bvModal.hide('modal-4')
                window.location = result.url
        } else {
            this.isLoading = false
            if (result.booking) {
                this.booking = false
                this.$toast.error({
                    title: 'Error',
                    message: this.$t('external_research.you_have_already_subscribe_in_this_time_period'),
                    color: '#D6E09B'
                })
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
        this.isLoading = false
    }
  }
}
</script>
