
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('external_sidebar.publication_subscription') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('publication_management.pub_type')"
                  label-for="pub_type"
                  >
                  <b-form-select
                  plain
                  v-model="search.pub_type_id"
                  :options="publicationTypeList"
                  id="pub_type"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('publication_management.pub_title')"
                  label-for="pub_title"
                  >
                  <b-form-select
                  plain
                  v-model="search.pub_title_id"
                  :options="publicationTitleList"
                  id="pub_title"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="frequency"
            >
            <template v-slot:label>
                {{$t('publication_management.pub_frequency')}}
            </template>
                <b-form-input
                disabled
                id="frequency"
                v-model="search.frequency"
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="2" sm="12" xs="12">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('publication_management.publication') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <!-- <template v-slot:cell(frequency)="data">
                      <span>{{ $n(data.item.frequency, { useGrouping: false }) }}</span>
                    </template> -->
                    <template v-slot:cell(action)="data">
                      <b-button title="Subscribe" v-b-modal.modal-4 class="btn btn-danger btn-sm" @click="edit(data.item)">{{ $t('globalTrans.subscribe') }}</b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { subscriptionFormListApi, subscriptionFormListApiPaymentStatusApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import { mapGetters } from 'vuex'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
        editItemId: 0,
        search: {
            pub_title_id: 0,
            pub_type_id: 0,
            frequency: ''
        },
        publicationTitleList: []
    }
  },
  created () {
     this.paymentStatusUpdate()
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    publicationTypeList: function () {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTypeList.filter(itm => itm.status === 1).map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    formTitle () {
       return this.$t('external_sidebar.publication_subscription') + ' ' + this.$t('globalTrans.payment')
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('publication_management.pub_type'), class: 'text-left' },
          { label: this.$t('publication_management.pub_title'), class: 'text-left' },
          { label: this.$t('publication_management.pub_frequency'), class: 'text-left' },
          // { label: this.$t('publication_management.publication_fee_amount'), class: 'text-left' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'pub_type_name_bn' },
          { key: 'pub_title_name_bn' },
          { key: 'frequency' },
          // { key: 'fee_amount' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'pub_type_name' },
          { key: 'pub_title_name' },
          { key: 'frequency' },
          // { key: 'fee_amount' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
    'search.pub_type_id': function (newVal) {
      this.getPublicationTitleList()
    },
    'search.pub_title_id': function (newVal) {
      this.search.frequency = this.getFrequency(newVal)
    },
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (this.search.pub_type_id) {
                this.getPublicationTitleList()
            }
        }
    }
  },
  mounted () {
    core.index()
    flatpickr('#to_date', {})
    flatpickr('#from_date', {})
  },
  methods: {
    async paymentStatusUpdate () {
      if (this.$route.query.status && this.$route.query.session_token) {
        const status = this.$route.query.status
        const sessionToken = this.$route.query.session_token
        const data = {
            session_Token: sessionToken
        }
        let result = null
        result = await RestApi.postData(agriResearchServiceBaseUrl, subscriptionFormListApiPaymentStatusApi + status, data)
          if (result.success) {
            this.$toast.success({
              title: 'Success',
              message: result.message,
              color: '#D6E09B'
            })
          } else {
            this.$toast.error({
              title: 'Error',
              message: result.message
            })
          }
        this.$router.replace({ path: this.$route.path, query: {} })
        this.loadData()
      } else {
        this.loadData()
      }
    },
    getFrequency (Id) {
      const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTitleList.find(item => item.value === Id)
      return Obj.frequency
    },
    getPublicationTitleList () {
        this.publicationTitleList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTitleList.filter(item => item.status === 1 && item.pub_type_id === this.search.pub_type_id).map(obj => {
          if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    async searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    view (item) {
      this.editItemId = item.id
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, subscriptionFormListApi, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
          const tmpType = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTypeList.find(newItem => newItem.value === item.pub_type_id)
          const tmpTitle = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTitleList.find(newItem => newItem.value === item.id)
          const newData = {
              pub_type_name: tmpType !== undefined ? tmpType.text_en : '',
              pub_type_name_bn: tmpType !== undefined ? tmpType.text_bn : '',
              pub_title_name: tmpTitle !== undefined ? tmpTitle.text_en : '',
              pub_title_name_bn: tmpTitle !== undefined ? tmpTitle.text_bn : '',
              frequency: tmpTitle !== undefined ? tmpTitle.frequency : ''
          }
          return Object.assign({}, item, newData)
      })
      return listData
    }
  }
}
</script>
